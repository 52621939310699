import { SummaryData } from "./DrillingSummary"
import type { HoleDescription } from "./HoleDescriptionForm"
import type { DrillEquipment } from "./DrillEquipmentSelect"
import type { MatrixData } from "./Matrix"
import { DrillHeaderForm } from "@/components/forms/DrillHeader"
import { DateTime } from "luxon"

export const validateHoleData = (holeData: HoleDescription) => {
    const errors: Record<string, string> = {}
    if (holeData.hole_number === '') {
        errors.hole_number = 'Hole number is required'
    }
    if (holeData.depth_planned === 0) {
        errors.depth_planned = 'Depth planned is required'
    }
    if (holeData.depth_drilled === 0) {
        errors.depth_drilled = 'Depth drilled is required'
    }
    if (holeData.depth_measured === 0) {
        errors.depth_measured = 'Depth measured is required'
    }
    if (holeData.angle === 0) {
        errors.angle = 'Angle is required'
    }
    if (holeData.clean === 0) {
        errors.clean = 'Clean is required'
    }
    if (holeData.broken_steel === 0) {
        errors.broken_steel = 'Broken steel is required'
    }
    if (holeData.broken_rocks === 0) {
        errors.broken_rocks = 'Broken rocks is required'
    }
    if (holeData.trepine_number === 0) {
        errors.trepine_number = 'Trepine number is required'
    }
    return errors
}

export const delaysChoices =  (t: (key: string) => string): {[index: string]:any} => ({
    redrilling: t('redrilling'),
    cleaning_blowing: t('cleaning_blowing'),
    displacement: t('displacement'),
    repair_maintenance: t('repair_maintenance'),
    meeting_lunch_break: t('meeting_lunch_break'),
    access: t('access'),
    dust_limit: t('dust_limit'),
    sound_limit: t('sound_limit'),
    drilling: t('drilling'),
    traveling: t('traveling'),
    standby: t('standby'),
    transport: t('transport'),
    downtime: t('downtime'),
    safety_meeting: t('safety_meeting'),
    preshift_inspection: t('preshift_inspection'),
    special_work: t('special_work'),
    mechanical_time: t('mechanical_time'),
    wait_time: t('wait_time')
});

export const yesNoChoices =  (t: (key: string) => string): {[index: string]:any } => ({
    yes: t('yes'),
    no: t('no')
});

export const initHolesArray = (cols: number, rows: number): HoleDescription[] => {
    const holesArray: HoleDescription[] = []
    for (let i = 0; i < cols * rows; i++) {
        holesArray.push({
            hole_number: String.fromCharCode(Math.ceil((i + 1) / cols) + 64) + ((i % rows) + 1),
            is_hole_number_changed: false,
            depth_planned: 0,
            matrix_input: 0,
            depth_measured: 0,
            water: '',
            angle: 0,
            clean: 0,
            remote: '',
            broken_steel: 0,
            broken_rocks: 0,
            trepine_number: 0,
            comment: '',
            trephine_number: '',
            hole_description: '',
            diameter_value: 0,
            diameter_units: '',
            time_casing: 0,
            casing_meters: 0,
            drilled: 0,
            redrilled: 0,
            cleaned: 0,
            pre_cutting: '',
            geotechnical_limit: '',
            hammer: '',
        })
    }
    return holesArray
}

export const calculateSummary = (matrixData: MatrixData): SummaryData => {
    const totalFeet: number = matrixData.holeDescriptions?.reduce((a, b) => a + b.drilled + b.redrilled + b.cleaned, 0) ?? 0
    const numOfHoles: number = matrixData.holeDescriptions?.length ?? 0
    const avgDepth: number = numOfHoles > 0 ? parseFloat((totalFeet / numOfHoles).toFixed(2)) : 0

    const m: number = totalFeet
    const m2: number = ((matrixData.spacing ?? 0) * Math.max(matrixData.cols - 1, 0) * (matrixData.burden ?? 0) * Math.max(matrixData.rows - 1))
    const m3: number = (((matrixData.spacing ?? 0) * Math.max(matrixData.cols - 1, 0) * (matrixData.burden ?? 0) * Math.max(matrixData.rows - 1)) * avgDepth)
    const drilled = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? 0 : b.drilled)
    }, 0)
    const drilledRemote: number = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? b.drilled : 0)
    }, 0)
    const redrilled: number = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? 0 : b.redrilled)
    }, 0)
    const redrilledRemote: number = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? b.redrilled : 0)
    }, 0)

    const cleaned: number = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? 0 : b.cleaned)
    }, 0)

    const cleanedRemote: number = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? b.cleaned : 0)
    }, 0)
    
    return {
        m: parseFloat(m.toFixed(2)),
        m2: parseFloat(m2.toFixed(2)),
        m3: parseFloat(m3.toFixed(2)),
        drilled: parseFloat(drilled.toFixed(2)),
        drilledRemote: parseFloat(drilledRemote.toFixed(2)),
        redrilled: parseFloat(redrilled.toFixed(2)),
        redrilledRemote: parseFloat((redrilledRemote).toFixed(2)),
        cleaned: parseFloat(cleaned.toFixed(2)),
        cleanedRemote: parseFloat((cleanedRemote).toFixed(2))
    }
}

export const makeMatrixData = (report?: Api.Report): { matrixData: MatrixData, summaryComment: string } | undefined => {
    if (!report) {
        return undefined
    }
    const form = report.form
    const cols = form.matrix ? form.matrix.length : 0
    const rows = form.matrix ? form.matrix[0]?.length : 0
    const holeDescriptions: HoleDescription[] = []
    form.mine?.forEach((hole: any) => {
        holeDescriptions.push({
            hole_number: hole.hole_number,
            is_hole_number_changed: false,
            depth_planned: parseFloat(hole.depth_planned),
            matrix_input: hole.matrix_input,
            depth_measured: parseFloat(hole.depth_measured),
            water: hole.water,
            angle: parseFloat(hole.angle),
            clean: parseFloat(hole.clean),
            remote: hole.remote,
            broken_steel: parseFloat(hole.broken_steel),
            broken_rocks: parseFloat(hole.broken_rocks),
            time_casing: parseFloat(hole.time_casing),
            casing_meters: parseFloat(hole.casing_meters),
            comment: hole.comment,
            trephine_number: hole.trephine_number,
            hole_description: hole.hole_description,
            diameter_value: parseFloat(hole.diameter_value),
            diameter_units: hole.diameter,
            drilled: parseFloat(hole.drilled),
            redrilled: parseFloat(hole.redrilled),
            cleaned: parseFloat(hole.cleaned),
            pre_cutting: hole.pre_cutting,
            geotechnical_limit: hole.geotechnical_limit,
            hammer: hole.hammer,
        })
    })

    const matrixData: MatrixData = {
        cols: cols,
        rows: rows,
        burden: form.burden ?? 0,
        spacing: form.spacing ?? 0,
        holeDescriptions: holeDescriptions.length == 0 ? initHolesArray(cols, rows) : holeDescriptions,
    }

    return { matrixData: matrixData, summaryComment: form.summary.comment }
}

export const makeDrillEquipment = (report?: Api.Report): DrillEquipment | undefined => {
    if (!report) {
        return undefined
    }
    const drill = report.equipment?.at(0)
    const compressor = report.equipment?.at(1)
    const drillEquipment: DrillEquipment = {
        drill: {
            equipment_id: drill?.id,
            start_time: drill?.pivot?.start_time ?? 0,
            end_time: drill?.pivot?.end_time ?? 0
        },
        compressor: {
            equipment_id: compressor?.id,
            start_time: compressor?.pivot?.start_time ?? 0,
            end_time: compressor?.pivot?.end_time ?? 0
        },
    }
    return drillEquipment
}
