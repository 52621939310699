import Input from "@/components/forms/Input"
import Radio from "@/components/forms/Radio"
import type { ChangeEvent } from "preact/compat"
import Options from "@/components/forms/Options"
import { useTranslation } from 'react-i18next'
import { yesNoChoices } from "./utils"

export type HoleDescription = {
    [key: string]: any
    hole_number: string
    trephine_number: string
    is_hole_number_changed: boolean
    depth_planned: number
    matrix_input: number
    drilled: number
    redrilled: number
    cleaned: number
    depth_measured: number
    water: string
    angle: number
    clean: number
    hole_description: string
    remote: string
    broken_steel: number
    broken_rocks: number
    casing_meters: number
    comment: string
    diameter_value: number
    pre_cutting: string
    geotechnical_limit: string
    hammer: string
}

type Props = {
    hole: HoleDescription
    index: number
    handleInput: (index: number, field: string, value: string | number) => void
    borers: Api.Equipment[] | undefined
    units: string
}

const HoleDescriptionForm: FC<Props> = ({hole, index, handleInput, ...p}) => {
    const { t, i18n } = useTranslation('driller')
    const remoteChoices = yesNoChoices(t)

    return (
        <>
            <Input
                className='span6'
                type='text'
                value={hole.hole_number}
                name={`form[mine][${index}][hole_number]`}
                label={t('hole_number')}
                onChange={(e: ChangeEvent) => handleInput(index, 'hole_number', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='text'
                value={hole.trephine_number}
                name={`form[mine][${index}][trephine_number]`}
                label={t('trephine_number')}
                onChange={(e: ChangeEvent) => handleInput(index, 'trephine_number', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='text'
                value={hole.hammer}
                name={`form[mine][${index}][hammer]`}
                label={t('hammer')}
                onChange={(e: ChangeEvent) => handleInput(index, 'hammer', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.diameter_value}
                name={`form[mine][${index}][diameter_value]`}
                label={t('diameter')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'diameter_value', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />        
            <Input
                className='span3-nb'
                type='text'
                value={hole.angle}
                decimal
                name={`form[mine][${index}][angle]`}
                label={t('angle')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'angle', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />    
             <Input
                className='span6-nb'
                type='number'
                decimal
                value={hole.depth_planned}
                name={`form[mine][${index}][depth_planned]`}
                label={t('planned')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_planned', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span6-nb'
                type='number'
                decimal
                value={hole.drilled}
                name={`form[mine][${index}][drilled]`}
                label={t('drilled')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'drilled', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span6-nb'
                type='number'
                decimal
                value={hole.redrilled}
                name={`form[mine][${index}][redrilled]`}
                label={t('redrilled')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'redrilled', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span6-nb'
                type='number'
                decimal
                value={hole.cleaned}
                name={`form[mine][${index}][cleaned]`}
                label={t('cleaned')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'cleaned', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.depth_measured}
                name={`form[mine][${index}][depth_measured]`}
                label={t('measured')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_measured', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.broken_rocks}
                name={`form[mine][${index}][broken_rocks]`}
                label={t('broken_rocks')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'broken_rocks', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.broken_steel}
                name={`form[mine][${index}][broken_steel]`}
                label={t('broken_steel')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'broken_steel', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                decimal
                value={hole.casing_meters}
                name={`form[mine][${index}][casing_meters]`}
                label={t('casing_meters')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'casing_meters', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('remote')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][remote]`}
                        choices={remoteChoices}
                        checked={hole.remote == '' ? t('no') : parseYesNoOption(hole.remote, i18n.language)}
                        prefered='name'
                        onChange={(value: string) => handleInput(index, 'remote', value)}
                    />
                </div>
            </div>

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('water')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][water]`}
                        choices={remoteChoices}
                        checked={hole.water == '' ? t('no') : parseYesNoOption(hole.water, i18n.language)}
                        prefered='name'
                        onChange={(value: string, name?: string) => handleInput(index, 'water', value)}
                    />
                </div>
            </div>

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('pre_cutting')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][pre_cutting]`}
                        choices={remoteChoices}
                        checked={hole.pre_cutting == '' ? t('no') : parseYesNoOption(hole.pre_cutting, i18n.language)}
                        prefered='name'
                        onChange={(value: string, name?: string) => handleInput(index, 'pre_cutting', value)}
                    />
                </div>
            </div>

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('geotechnical_limit')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][geotechnical_limit]`}
                        choices={remoteChoices}
                        checked={hole.geotechnical_limit == '' ? t('no') : parseYesNoOption(hole.geotechnical_limit, i18n.language)}
                        prefered='name'
                        onChange={(value: string, name?: string) => handleInput(index, 'geotechnical_limit', value)}
                    />
                </div>
            </div>

            <label>
                {t('result')}
                <select name={`form[mine][${index}][hole_description]`} onChange={(e: ChangeEvent) => handleInput(index, 'hole_description', (e.target as HTMLInputElement).value)}>  
                    <Options d={hole.hole_description} t={t} options={['NBT - Non Break Through - Dans le roc solide', 'TC - Tout Cassé - Dans la roche cassée', 'BT - Break Through - Défonçant dans le vide', 'BMT - Break Through Muck - Défonçant dans la roche cassées']}/>
                </select>
            </label>

            <Input
                className='span6'
                type='text'
                value={hole.comment}
                name={`form[mine][${index}][comment]`}
                label={t('forms:comments')}
                onChange={(e: ChangeEvent) => handleInput(index, 'comment', (e.target as HTMLInputElement).value)}
            />
        </>

    )
}

function parseYesNoOption(value: string, language: string): string {
    if (language == 'en') {
        if (value == 'Oui') {
            return 'Yes'
        } else if (value == 'Non') {
            return 'No'
        } else {
            return value
        }
    } else {
        if (value == 'Yes') {
            return 'Oui'
        } else if (value == 'No') {
            return 'Non'
        } else {
            return value
        }
    }
}

export default HoleDescriptionForm