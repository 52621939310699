import MineDrilling from '@/pages/reports/driller_prod/MineDrilling'
import Report from '@/components/Report'
import { useFilledReport, useReports } from '@/hooks'
import { useTranslation } from 'react-i18next'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import { useEffect, useState } from 'preact/hooks'
import { makeMatrixData } from './utils'
import { hoursToTime } from '@/utils'
import Btn from '@/components/forms/Btn'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { isQueryLoading } from '@/helpers'
import Loading from '@/components/Loading'
import DrillHeader from '@/components/forms/DrillHeader'
import { getProjectsWithTask } from '@/api/resources'
import { useQuery } from '@tanstack/react-query'
import { TaskSelect } from '@/components/forms/FuzzySelect'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import { groupBy } from 'lodash-es'
import Input from '@/components/forms/Input'


type TaskEntry = {
	id: number
	task_id: number
	myoctans_task_id: number
	BT: string
	traveling_time: number
	work_order_time: number
}

const DrillerProdReport: FC<ReportProps> = p => {
	const { t } = useTranslation('driller')
	const [formOption, setFormOption] = useState<string | undefined>(undefined)
	const [formOptionConfirmed, setFormOptionConfirmed] = useState(false)
	const [reportId, setReportId] = useState<number | undefined>(undefined)
	const r = useFilledReport(reportId?.toString() ?? p.report_id)
	const [ timeEntries, setTimeEntries ] = useState<TaskEntry[]>([])
	const [units, setUnits] = useState(r?.form?.units ?? 'meters')
	const [drillingType, setDrillingType] = useState(r?.form?.form_type ?? 'mine')
	const { status: reportsStatus, fetchStatus: reportsFetchStatus, data: reports } = useReports()
	const { status: projectsStatus, fetchStatus: projectsFetchStatus, data: projects } = useQuery({
		queryKey: ['projects-tasks'],
		queryFn: getProjectsWithTask,
	})

	useEffect(() => {
		if (r && projects != undefined) {
			const project = projects?.find(p => p.id == r.project_id)
			if (!project) {
				return
			}
			const timeEntries = project.tasks? project.tasks.map(task => {
				const timeEntry = r.form.time_entries?.find((entry: any) => {
					return entry.task_id == task.id
				})
				return {
					task_id: task.id,
					myoctans_task_id: task.myoctans_task_id,
					BT: task.BT,
					traveling_time: timeEntry?.traveling_time ? parseFloat(timeEntry.traveling_time) : 0,
					work_order_time: timeEntry?.work_order_time ? parseFloat(timeEntry.work_order_time) : 0,
				} as TaskEntry
			}): [] as TaskEntry[]
			setTimeEntries(timeEntries)
		}
	}, [r, projects])


	const defaultCollapsed = reportId != undefined && !p.disabled
	const { setBlob: setSupervisorSignature, append: appendSupervisorSignature } = useDrawing(
		'form[supervisor_signature]',
		'supervisor_signature.png'
	)

	const { setBlob: setDrillerSignature, append: appendDrillerSignature } = useDrawing(
		'form[driller_signature]',
		'driller_signature.png'
	)

	const { setBlob: setClientSignature, append: appendClientignature } = useDrawing(
		'form[client_signature]',
		'client_signature.png'
	)

	const removeNulls = (fd: FormData) => {
		if (!fd.get('equipments[0][equipment_id]')) {
			fd.delete('equipments[0][id]')
			fd.delete('equipments[0][start_time]')
			fd.delete('equipments[0][end_time]')
			fd.delete('equipments[0][duration]')
			fd.delete('equipments[0][diameter]')
		}

		if (!fd.get('equipments[1][equipment_id]')) {
			fd.delete('equipments[1][equipment_id]')
			fd.delete('equipments[1][start_time]')
			fd.delete('equipments[1][end_time]')
			fd.delete('equipments[1][duration]')
		}

		for (var pair of fd.entries()) {
			if ((!pair[1] || pair[1] == '') && !pair[0].includes('matrix')) {
				fd.delete(pair[0])
			}
		}
	}

	const submitArgs = {
		async process(fd: FormData) {
			removeNulls(fd)
			appendSupervisorSignature(fd)
			appendDrillerSignature(fd)
			appendClientignature(fd)
		}
	}

	const handleFormOptionConfirmation = (e: Event) => {
		setFormOptionConfirmed(true)
	}

	const handleProjectMatch = (project: object | undefined) => {
		const projectMatch = project as Api.Project
		const timeEntries = projectMatch?.tasks ? projectMatch.tasks.filter(task => !task.is_archived).map(task => {
			return {
				id: task.id,
				task_id: task.id,
				myoctans_task_id: task.myoctans_task_id,
				BT: task.BT,
				traveling_time: 0,
				work_order_time: 0,
			} as TaskEntry
		}) : []
		setTimeEntries(timeEntries)
	}

	const handleTimeChange = (e: Event, i: number, field: string) => {
		const target = e.target as HTMLInputElement
		const value = parseFloat(target.value)
		if (timeEntries) {
			const newTimeEntries = timeEntries.map((entry, j) => {
				if (i == j) {
					return {...entry, [field]: value}
				}
				return entry
			})
			setTimeEntries(newTimeEntries)
		}
	}

	if (!p.disabled && (!formOption || !formOptionConfirmed) && !p.report_id) {
		return (
			<>
				<input type="radio" name="formOption" id="continueForm" onClick={(e) => setFormOption('continue_form')} />
				<label htmlFor="continueForm">{t('continue_existing_form')}</label>
				<br />
				<br />
				<input type="radio" name="formOption" id="newForm" onClick={(e) => setFormOption('new_form')} />
				<label htmlFor="newForm">{t('create_new_form')}</label>
				<br />
				<br />
				<Btn class={classNames('w-25')} onClick={handleFormOptionConfirmation}>
					{t('forms:next')}
				</Btn>
			</>
		)
	}

	if ((isQueryLoading(reportsStatus, reportsFetchStatus) || isQueryLoading(projectsStatus, projectsFetchStatus)) && formOption === 'continue_form' && !p.report_id) {
		return (
			<Loading />
		)
	}

	if (formOption === 'continue_form' && !reportId && ! p.report_id) {
		return (
			<ol class='grid grid-cols-2 gap-4'>
				{(reports && projects) && reports.filter(r => r.form_type === 'driller_prod')
					.filter(r => r?.form?.is_complete != '1')
					.map(r => (
						<li>
							<a
								onClick={(e) => setReportId(r.id)}
								class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
							>
								<div class='mb-auto font-medium text-gray-800'>
									{t('forms:project') + ': ' + (projects?.find(t => t.id === r.project_id)?.name ?? '-')}
								</div>
								<div class='mt-2 text-sm text-gray-600'>
									{r.form?.drilling_block != undefined ? t('drilling_block') + ': ' + r.form.drilling_block : ''}
								</div>
								<div class='mt-2 text-right text-sm text-gray-600'>
									{DateTime.fromISO(r.date_in).toISODate()} 
								</div>
							</a>
						</li>
					))}
			</ol>
		)
	}

	const indexedTimeEntries = timeEntries?.map((entry, index) => ({ ...entry, index }));

	return (
		<Report submitArgs={submitArgs} type='driller_prod' report={r} disabled={p.disabled === 'disabled'}>
			<input type='hidden' name='version' defaultValue='2' />
			<input type="hidden" name={`form[units]`} value={units} />
			<input type="hidden" name="_method" value={r?.id != undefined ? 'PUT' : 'POST'}/>
			<DrillHeader report={r} disabled={p.disabled === 'disabled'} setUnits={setUnits} setDrillingType={setDrillingType} units={units} drilllingType={drillingType} handleProjectMatch={handleProjectMatch}/>
			<MineDrilling value={makeMatrixData(r)} name='form' defaultCollapsed={defaultCollapsed} units={units} drillingType={drillingType}></MineDrilling>

			<CollapsibleFieldset title={t('driller:time_entries')}>
				{timeEntries ? Object.entries(groupBy(indexedTimeEntries, 'BT')).map(([category, tasks], index) => (
					
					<fieldset key={index}>
						<legend>{category}</legend>
						{tasks.map((task, i) => (
							<>
								<input type="hidden" name={`form[time_entries][${task.index}][task_id]`} value={task.task_id} />
								<TaskSelect
									required
									name={`form[time_entries][${task.index}][myoctans_task_id]`}
									selectedId={task.myoctans_task_id as number}
									label={t('task')}
									readOnly={true}
									className={'span2'}
								/>
								<Input
									label={t('hours')}
									className='span2'
									name={`form[time_entries][${task.index}][work_order_time]`}
									value={task.work_order_time}
									onBlur={(e) => handleTimeChange(e, task.index, 'work_order_time')}
									type="number"
									decimal
								/>
								<Input
									label={t('traveling')}
									className='span2'
									name={`form[time_entries][${task.index}][traveling_time]`}
									value={task.traveling_time}
									onBlur={(e) => handleTimeChange(e, task.index, 'traveling_time')}
									type="number"
									decimal
								/>
							</>
						))}
					</fieldset>
				)) :
					<p class="text-center">{t('driller:select_a_project')}</p>
				}
			</CollapsibleFieldset>
			<Input
				className='span6'
				label={t('production_report_hours')}
				type={'text'}
				disabled
				value={hoursToTime(timeEntries.reduce((acc, entry) => acc + entry.work_order_time, 0))}
			/>
            <Drawing label={t('supervisor_signature')} setBlob={setSupervisorSignature}  /> 
            <Drawing label={t('driller_signature')} setBlob={setDrillerSignature} />
            <Drawing label={t('client_signature')} setBlob={setClientSignature} />
		</Report>
	)
}
export default DrillerProdReport
