
import { getDailyReports, getTimeEntriesLastMonth } from '@/api/reports'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { isQueryLoading } from '@/helpers'
import Loading from '@/components/Loading'

type FilledReport = {
	id: number
	myoctans_type: string
	date_in: string
	display_name: string
}

const FilledDailyReports: FC<{ path: string; type?: TimeEntryType }> = p => {
	const { t } = useTranslation('routes')
	const { status: timeEntriesStatus, fetchStatus: timeEntriesFetchStatus, data: timeEntries } = useQuery<Api.TimeEntry[]>({
		queryKey: ['time-entries'],
		queryFn: getTimeEntriesLastMonth
	})
	const { status: dailyReportsStatus, fetchStatus: dailyReportsFetchStatus, data: dailyReports } = useQuery<Api.DailyReport[]>({
		queryKey: ['daily-reports'],
		queryFn: getDailyReports
	})

	if (isQueryLoading(timeEntriesStatus, timeEntriesFetchStatus) || isQueryLoading(dailyReportsStatus, dailyReportsFetchStatus)) {
		return <Loading />
	}

	const reportRoute = (report: FilledReport) => {
		let route = ''
		if (report.myoctans_type == 'planned_report') {
			route = `/planned_report/${report.id}/disabled`
		} else {
			route = `/reports/${report.myoctans_type}/${report.id}/disabled`
		}
		return route
	}

	const mapDailyReports = () => {
        return dailyReports?.map((val: Api.DailyReport) => {
            return {
                id: val.id,
                myoctans_type: 'daily_tasks',
                date_in: val.time_entries[0].date_in,
                display_name: val.project.name
            } as FilledReport
        }) || []
    }

	const mergedReports = timeEntries?.filter((val: Api.TimeEntry) => val.daily_report_id == null)
							.filter((val: Api.TimeEntry) => val.myoctans_type != null)
							.map((val: Api.TimeEntry) => {
								return {
									id: val.id,
									myoctans_type: val.myoctans_type,
									date_in: val.date_in,
									display_name: val.task.name
								} as FilledReport
							}).concat(mapDailyReports())
							.sort((a, b) => DateTime.fromISO(b.date_in).toMillis() - DateTime.fromISO(a.date_in).toMillis())

	return (
		<ol class='grid grid-cols-2 gap-4'>
			{(mergedReports) && mergedReports
								.filter(val => val.myoctans_type != null)
								.map(r => (
					<li>
						<a
							href={reportRoute(r)}
							class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
						>
							<div class='mt-2 text-right text-sm text-gray-600'>
                                { t(r.myoctans_type) }
                            </div>
                            <div class='mt-2 text-right text-sm text-gray-600'>
                                { r.display_name }
                            </div>
							<div class='mt-2 text-right text-sm text-gray-600'>
                                {DateTime.fromISO(r.date_in).toISODate()} 
							</div>
						</a>
					</li>
				))}
		</ol>
	)
}
export default FilledDailyReports
